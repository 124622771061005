import React, { Component, useState, useEffect, useContext, useReducer, useMemo, useRef, lazy, Suspense } from 'react';
import { NavLink, Outlet, Routes, Route, useParams, useSearchParams, useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

/* That's global State storage */
import selectContext from './components/library/SelectContext';
// And then for use add in component: import SelectContext from './SelectContext';
// And then for use add in component: const { value } = useContext(SelectContext);

/* That's default "Alert" component */
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
/* Use for show information: toast.info("text"), toast.warn("text"), toast.error("text"), toast.success("text"); */
/* Use:
  <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
  /> 
*/

  /* That's default "Loader spinner" component */
import { Audio } from 'react-loader-spinner';
/* Use: {loading && <Audio className="loader" height="80" width="80" radius="9" color="green" ariaLabel="loading" />} */

  /* That's styles */
import './css/normalize/modern-normalize.css'; /* Normalize style file */
import './css/styles.js' /* Other style files includes here */
import './css/main.css'; /* Default compiled SASS/SCSS style files */
// import './App.css'; 
// import './css/components/Modal_v.0.5.css';
// import './css/components/Music_v.1.2.css';
// import './css/components/Theme_v.0.2.css';
// import './css/components/Language_v.0.5.css';

  /* That's SVG */
import Sprite from './components/library/Sprite'; /* Example for use: <Sprite id="logo" /> */

  /* That's Hooks */
import runGenId from './hooks/Hook_Generator_id_v.1.1';
import useLocalStorage from './hooks/Hook_Local_Storage_v.0.5';

  /* That's Components */
import Header from './components/Header';
import Footer from './components/Footer';

import {Language, LanguageContext} from './components/library/Language_v.0.5';
import Modal from "./components/library/Modal_v.0.7";
// import Music from './components/library/Music_v.1.2';
// import Theme from './components/library/Theme_v.0.4';
import AnimatedText from './components/AnimatedText.jsx';
import Share from './components/library/Share_v.0.2.jsx'


// import Theme from './components/Theme';
// <Theme />

/* Используем lazy для загрузки компонентов */ 
// const About = lazy(() => import('./components/'));
const Portal = lazy(() => import('./components/Portal.jsx'));


const App = () => {


  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);  // Отслеживаем загрузку
  const [select, setSelect] = useState(null);
  const [local, setLocal] = useLocalStorage('key', 'default_value');

  const [language, setLanguage] = useState('EN');

    /* That's link on bacic page */  
  const location = useLocation();
  // console.log(location);

    /* That's global State storage */
  const [storage, setStorage] = useState({
    data: data,
    setData: setData,
    loading: loading,
    setLoading: setLoading,
    select: select,
    setSelect: setSelect,
    language: language,
    setLanguage: setLanguage,
    local: local,
    setLocal: setLocal,
    runGenId: runGenId(),
  });

  useEffect(() => {
    setStorage(prevStorage => ({
      ...prevStorage,
      data,
      loading,
      select,
      language,
      local
    }));
  }, [data, loading, select, language, local]);



// -------------------- Language ---------------------
    const [text, setText] = useState({
      lang_namePage: `Test page for Websites`,
      notExist: 'Page is not exist (404).',
      return: 'Return',
      domain: "Place website for test on this is domain.",
      price: "Price",
      time: "Timeleft (days)",
      service: "Service",
      notice: "Maximal size of project for test: ",
    });
  
useEffect(() => {
  if (language === "EN") {
    setText({
      lang_namePage: `Test page for Websites`,
      notExist: 'Page is not exist (404).',
      return: 'Return',
      domain: "Place website for test on this is domain.",
      price: "Price",
      time: "Timeleft (days)",
      service: "Service",
      notice: "Maximal size of project for test: ",
    });
  } else if (language === "RU") {
    setText({
      lang_namePage: `Тест страница для Вебсайтов`,
      notExist: 'Page is not exist (404).',
      return: 'Вернуться на главную страницу',
      domain: "Разместить вебсайт для теста на этот домен",
      price: "Цена",
      time: "Срок (день)",
      service: "Услуга",
      notice: "Максимальный размер проекта для тестирования: ",
    });
  } else {
    setText({
      lang_namePage: `Test page for Websites`,
      notExist: 'Page is not exist (404).',
      return: 'Return',
      domain: "Place website for test on this is domain.",
      price: "Price",
      time: "Timeleft (days)",
      service: "Service",
      notice: "Maximal size of project for test: ",
    });
  };

}, [language]);

  

// -------------------- Re-render fix ---------------------
  
// const [renderCount, setRenderCount] = useState(0);
// useEffect(() => {
//   if (renderCount >= 3) {  // Появление сообщения на третьем рендере (индекс 2)
//     toast.info(`Render...`);
//     console.log(`Render...`);
//   }
//   // Увеличиваем счетчик рендеров после каждого рендера
//   setRenderCount(prevCount => prevCount + 1);
// }, []);

  
// -------------------- LOADER SPINER ---------------------  

    // Загрузчик
  const loader = <div className='loader'>
    <Audio className='loader' height="80" width="80" radius="9" color="inherit" ariaLabel="loading" />
    {/* <p>{text.loading}</p> */}
  </div>;

  
  // -------------------- Website components ---------------------  
  
  const parts =
    <Suspense fallback={loader}>
      <Routes>
        {/* <Route path="/"> */}
        <Route path="/" index element={
        <>
          <AnimatedText string={text.lang_namePage} />
          <table>
            {/* <caption>
              {text.notice + "50 Mb"}
            </caption> */}
            <thead>
              <tr>
                <th scope="col">{text.service}</th>
                <th scope="col">{text.price}</th>
                <th scope="col">{text.time}</th>
              </tr>
            </thead>
              <tbody>
                  {/* <tr>
                      <td>{text.domain}</td>
                      <td>$0.50</td>
                      <td>7</td>
                  </tr> */}
                  <tr>
                      <td>{text.domain}</td>
                      <td>$0.50</td>
                      <td>15</td>
                  </tr>
                  <tr>
                      <td>{text.domain}</td>
                      <td>$1.00</td>
                      <td>30</td>
                  </tr>
              </tbody>
            <tfoot>
              <tr>
                <th scope="row" colspan="2">{text.notice}</th>
                <td>50 Mb</td>
              </tr>
            </tfoot>
          </table>
        </>
        }></Route>
        {/* <Route path="/" index element={<Portal />}></Route> */}
        {/* <Route path="about" index element={language === "RU" ? <p>Компонент - Страница о нас</p> : <p>Component - Page About</p>} /> */}
        {/* <Route path="about" element={<About />} /> */}
        {/* </Route> */}
        <Route path="*" element={<><p>{text.notExist}</p><a href='./'>{text.return}</a></>}></Route>
      </Routes>
    </Suspense>;
  
  
  
  return ( 
    <>
    <selectContext.Provider value={{storage}}>
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <Header />
          <main className="main">
            {/* <AnimatedText string={text.lang_namePage} /> */}
          {loading ? loader || <p className='loader_text'>Loading...</p> : parts}
          {/* <Music /> */}
          {/* <Language /> */}
      </main>
      <Footer />  
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme={document.body.getAttribute('data-theme')} />   
    </LanguageContext.Provider>
    </selectContext.Provider>
    <Modal content={select} />
    </>
  );
}

export default App;

