import React, { Component, useState, useEffect, useContext, useReducer, useMemo, useRef, lazy, Suspense } from 'react';
import { NavLink, Outlet, Routes, Route, useParams, useSearchParams, useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

import SelectContext from './library/SelectContext';


import Sprite from './library/Sprite'; /* Example for use: <Sprite id="logo" /> */
import Share from './library/Share_v.0.2';

import {Language, LanguageContext} from './library/Language_v.0.5';
// import Music from './library/Music_v.1.2';
import Theme from './library/Theme_v.0.4';

const Header = () => { 

    const { storage } = useContext(SelectContext);
    const [language, setLanguage] = useState('EN');
    const [text, setText] = useState({
      lang_home: `Home page`,
      lang_about: "About page",
    });

useEffect(() => {
  if (language === "EN") {
    setText({
      lang_home: `Home page`,
      lang_about: `About page`,
    });
  } else if (language === "RU") {
    setText({
      lang_home: `Домашняя страница`,
      lang_about: `Страница о нас`,
    });
  } else {
    setText({
      lang_home: `Home page`,
      lang_about: `About page`,
    });
  };

    storage.setLanguage(language);
}, [language]);

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
        <header className="header">
            {/* <h1>Test Page for Website</h1> */}
            {/* <nav>
                <ul>
                    <li><NavLink className='mark' to="/">{text.lang_home}</NavLink></li>
                    <li><NavLink className='mark' to="about">{text.lang_about}</NavLink></li>
                    <li><NavLink className='mark' to="about" state={{ from: location }}>About</NavLink></li>
                </ul>
            </nav> */}
            <section className='settings'>
                {navigator.share && <Share />}
                <Theme />
                {/* <Music /> */}
                <Language />
            </section>
        </header>
        </LanguageContext.Provider>
    );
};


export default Header;
