import React, { Component, useState, useEffect, useContext, useReducer, useMemo, useRef, lazy, Suspense } from 'react';
import { NavLink, Outlet, Routes, Route, useParams, useSearchParams, useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

import Sprite from './library/Sprite'; /* Example for use: <Sprite id="logo" /> */
import selectContext from './library/SelectContext';

const Footer = () => { 
    
    return (
        <footer className="footer">
            {/* <h3>Contact:</h3> */}
            <address>
                <a className="contact" href="https://telegram.me/ovcharuk_tolik" target='_blank'><Sprite id="telegram" /><p> Telegram - @ovcharuk_tolik</p></a>
                <a className="contact" href="https://wa.me/380674639606" target='_blank'><Sprite id="whatsapp" /><p>WhatsApp</p></a>
                {/* <a className="contact" href="https://wa.me/380674639606" target='_blank'><Sprite id="whatsapp" /><p>WhatsApp - https://wa.me/qr/LSFU2GDDFSZXP1</p></a> */}
            </address>
            <a className='creator' href='https://github.com/Anatolii-Ovcharuk' target='_blank'>Made by Anatolii Ovcharuk (Github page)</a>
        </footer>
    );
};


export default Footer;

